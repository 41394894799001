import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './contactsPageContent.scss';
import textUtils from '../../utils/textUtils';

const ContactsPageContent = (
  {
    color,
    content: {
      contacts: {
        common = {},
        servolux = {},
        vacancies = {},
      },
      buttons,
      titles,
    },
    hash,
    isMobile,
  },
) => {
  const tag = hash.replace(/#/g, '');
  const tags = ['common', 'servolux', 'vacancies', 'Ритейл'];

  let tab = tag;
  if (tags.indexOf(tag) === -1) {
    tab = 'common';
  }
  const [activeTab, tabSwitch] = useState(tab);
  return (
    <div className={`contactsWrapper contactsWrapper_${color}`}>
      <div className="contactsWrapper_col contactsWrapper_col_first">
        {!isMobile && (<Link to='/' className='logo'/>)}
        <h1 className="header">{titles.title_contacts}</h1>
        <div className="tabs-wrapper">
          <div className="tabs-container">
            <div
              className={activeTab === 'common' ? 'common tab active' : 'common tab'}
              onClick={() => { tabSwitch('common'); }}
            >
              {buttons.button_common_contacts}
            </div>
            <div
              className={activeTab === 'servolux' ? 'servolux tab active' : 'servolux tab'}
              onClick={() => { tabSwitch('servolux'); }}
            >
              {buttons.button_servolux}
            </div>
            <div
              className={activeTab === 'vacancies' ? 'vacancies tab active' : 'vacancies tab'}
              onClick={() => { tabSwitch('vacancies'); }}
            >
              {buttons.button_vacancies}
            </div>
            <div
              className={activeTab === 'retail' ? 'retail tab active' : 'retail tab'}
              onClick={() => { tabSwitch('retail'); }}
            >
              Ритейл
            </div>  
          </div>
          <div className={activeTab === 'common' ? 'common tab-content active' : 'common tab-content'}>
            <div className="nums">
              <div className="item item_hot-line">
                <div className="name hot-line">{common.hot_line.title}</div>
                <a href={`tel:${textUtils.clearSymbols(common.hot_line.value)}`} type='phone' className="info">{common.hot_line.value}</a>
              </div>
              {
                isMobile && (
                  <div className="item item_hot-line">
                    <div className="name">{common.email.title}</div>
                    <a href={`mailto:${common.email.value}`} className="info email">{common.email.value}</a>
                  </div>
                )
              }
              <div className="item item_hot-line thirst">
                <div className="name">{common.feedback.title}</div>
                <a href={`tel:${textUtils.clearSymbols(common.feedback.value)}`} className="info">{common.feedback.value}</a>
              </div>
              <div className="item">
                <div className="name">{common.inner_number.title}</div>
                <a href={`tel:${textUtils.clearSymbols(common.inner_number.value)}`} className="info">{common.inner_number.value}</a>
                <div className="tip">{common.inner_number.subtitle}</div>
              </div>
              {
                !isMobile && (
                  <div className="item">
                    <div className="name">{common.email.title}</div>
                    <a href={`mailto:${common.email.value}`} className="info email">{common.email.value}</a>
                  </div>
                )
              }
              <div className="item international">
                {!isMobile && (<div className="name international">&apos;</div>)}
                <a href={`tel:${textUtils.clearSymbols(common.international_number.value)}`} className="info">{common.international_number.value}</a>
                <div className="tip">{common.international_number.subtitle}</div>
              </div>
              <div className="item">
                <div className="name">{common.sales_managers.title}</div>
                <a href={`tel:${textUtils.clearSymbols(common.sales_managers.value)}`} className="info">{common.sales_managers.value}</a>
              </div>
            </div>
            {
              !isMobile && (
                <>
                  <a className="address" href={`https://yandex.ru/maps/?text=${common.address.value}`} target='_blank' rel="noreferrer">
                    {common.address.value}
                  </a>
                  <a href='mailto:info@petruha.by' className="write-letter">{buttons.button_feedback}</a>
                </>
              )
            }
          </div>
          <div className={activeTab === 'servolux' ? 'servolux tab-content active' : 'servolux tab-content'}>
            <div className="nums">
              <div className="item vostok">
                <div className="name">{servolux.inner_number.title}</div>
                <a href={`tel:${textUtils.clearSymbols(servolux.inner_number.value)}`} className="info">{servolux.inner_number.value}</a>
                <div className="tip">{servolux.inner_number.subtitle}</div>
              </div>
              <div className="item">
                <div className="name">{servolux.email.title}</div>
                <a href={`mailto:${servolux.email.value}`} className="info email">{servolux.email.value}</a>
              </div>
            </div>
            <a className="address" href={`https://yandex.ru/maps/?text=${servolux.address.value}`} target='_blank' rel="noreferrer">
              {servolux.address.value}
            </a>
            {!isMobile && (<a href='mailto:info@petruha.by' className="write-letter">{buttons.button_feedback}</a>)}
          </div>
          <div className={activeTab === 'vacancies' ? 'vacancies tab-content active' : 'vacancies tab-content'}>
            <div className="nums vacancies">
              <div className="item vacancy">
                <div className="name">{vacancies.rabotaby.title}</div>
                <a href={vacancies.rabotaby.value} target='_blank' rel='noreferrer' className="info">
                  <div className="site-name">{vacancies.rabotaby.subtitle}</div>
                </a>
              </div>
              <div className="item vacancy">
                <div className="name">{vacancies.hhru.title}</div>
                <a href={vacancies.hhru.value} target='_blank' rel='noreferrer' className="info">
                  <div className="site-name">{vacancies.hhru.subtitle}</div>
                </a>
              </div>
            </div>
          </div>
          <div className={activeTab === 'retail' ? 'retail tab-content active' : 'retail tab-content'}>
            <div className="nums b-retail">
              <div className="item b-retail">
                <div className="name">Обратная связь</div>
                <a href="tel:+375297471140" className="info">
                  <div className="site-name">+375 29 747 11 40</div>
                </a>
                <a href="./to_petruha.pdf" className="button-pdf" target="_blank">
                  <div className="site-name">Перечень торговых объектов</div>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="contactsWrapper_col contactsWrapper_col_second">
        <div className="background-wrapper" />
        <Link to='/' className="logo"/>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
    content: state.ContentReducer.content,
  }),
  () => ({
  }),
)(ContactsPageContent);
